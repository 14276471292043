$field: rgb(70,70,70);

input[type="text"], 
input[type="email"], 
input[type="password"],
input[type="tel"], 
textarea { 
	border: 0 none; 
	padding: 10px;
	padding-top: 3px;
	margin: 0 0 10px 0;
	display: block;
	width: 100%;
	background-color: transparent;
	outline: none;
	
	&:hover {
		background-color: transparent;
	}
	
	&:active {
		background-color: transparent;
	}
	
}

.field {
	background-color: $field;
	padding-top: .6em;
	transition: .3s;
	
	&.submit {
		background-color: transparent;
	}
}

input[type=submit] {
	width: 100%;
	padding: 1em 0;
	cursor: pointer;
	text-align: center;
}

.ie {
	input[type="text"], 
	input[type="email"], 
	input[type="password"],
	input[type="tel"], 
	textarea { 
		border: 2px solid #bbb;
		border-right: 1px solid #eee;
		border-bottom: 1px solid #eee;
	}
}

label {
	display: block;
	color: $accent;
	font-size: .8em;
	line-height: 1em;
	font-weight: 700;
	text-transform: uppercase;
	padding: 0 10px 0;
}

.optional {
	font-size: .85em;
	opacity: .7;
}

input[type="checkbox"], input[type="radio"] { margin: 2px 0; }

::-webkit-input-placeholder {
   color: rgb(100,100,100);
}

:-moz-placeholder { /* Firefox 18- */
   color: rgb(100,100,100);  
}

::-moz-placeholder {  /* Firefox 19+ */
   color: rgb(100,100,100); 
}

:-ms-input-placeholder {  
   color: rgb(100,100,100);
}

form {
	margin-top: 4em;
	
	p {
		margin-bottom: 2em;
	}
	
	.icon {
		$w: 115px;
		width: $w;
		height: $w;
		border-radius: $w;
		background-color: $accent;
		text-align: center;
		margin: 0 auto;
		margin-bottom: 3em;
		
		img {
			position: relative;
			margin-top: 38px;
			z-index: 1;
		}
		
		&:after {
			$w: 60px;
			content: '';
			display: block;
			width: $w;
			height: $w;
			margin: 0 auto;
			border-radius: 2px;
			background-color: $accent;
			transform: rotate(45deg) translate(-17px, -17px);
		}
	}
	
	&.processing {
		
		input,
		textarea {
			pointer-events: none;
		}
		
		.field {
			opacity: .5;
			
			&.submit {
				opacity: 1;
				background-color: $accent;
				
				input {
					opacity: 0;
				}
				
			}
			
		}
		
	}
	
	&.success {
		
		.field {
			overflow: hidden;
			height: 0;
			margin: 0;
			padding: 0;
			
			&.submit {
				height: auto;
				background-color: rgb(160,207,103);
				
				input {
					opacity: 0;
				}
				
				&:after {
					content: '';
					display: block;
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					background: url(images/tick.png) no-repeat 50% 50%;
					background-image: url(images/tick.svg);
				}
				
			}
			
		}
		
	}
	
	&.error {
		
		.errorMessage {
			display: block;
		}
		
	}
	
	.errorMessage {
		display: none;
		background-color: rgb(226,1,119);
		margin: 1em 0;
		padding: .8em;
		color: white;
		border: 0;
	}
	
}

.field.submit {
	position: relative;
	padding-top: 0;
	margin-top: .6em;
	
	.loader {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		/*margin: 20px auto;*/
	}
}

@media screen and (min-width:$break1) {
	
	form {
		width: 400px;
		margin-right: auto;
		margin-left: auto;
	}
	
}
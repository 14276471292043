.col {
	display: block;
	margin-bottom: 2em;
	padding: 1px;
}

.cols4 {
	display: flex;
	flex-wrap: wrap;
	
	.col {
		width: 50%;
	}
}

@media screen and (min-width:$break1) {
	
	.cols {
		display: flex;
		flex-wrap: wrap;
		
		&.cols4 {
			
			.col {
				width: 25%;
			}
			
		}
		
		&.cols2 {
			
			.col {
				width: 47%;
				
				&:first-child {
					margin-right: 6%;
				}
			}
			
		}
	}
	
}

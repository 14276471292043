$black: 	rgb(20,20,20);
$white: 	rgb(255,255,255);

$copy: 		rgb(240,240,240);

$base:  	rgb(54,50,48);
$accent:	rgb(243,146,0);

$link: 		rgb(0,110,175);
$negativeLink: rgb(250,250,250);

$fb: 		rgb(59,89,152);
$tw: 		rgb(0, 172, 237);
$yt: 		rgb(179,18,23);

$break1: 	621px;
$break2: 	800px;
$break3: 	1000px;
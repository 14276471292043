body,
select,
input,
textarea,
button { 
	font-family: 'Lato', sans-serif;
	font-size: 18px;
	line-height: 1.45em;
	color: $copy;
	font-smooth: always;
	-webkit-font-smoothing: antialiased;
}

p,
ul,
ol,
ul.long li { 
	margin: 0 0 0.8em 0; 
}

h1 {
	font-weight: normal;
	font-size: 1.55em;
	line-height: 1.2em;
}

h2 {
	font-weight: normal;
	font-size: 1.55em;
	line-height: 1.2em;
	text-align: center;
	margin: 3em 0;
	
	&:before {
		content: '';
		display: block;
		margin: 0 auto 1em;
		height: 3px;
		width: 30%;
		background-color: $accent;
	}
}

.accent {
	color: $accent;
}

::-moz-selection { 
	background: rgb(10,10,10);
	background: rgba(0,0,0,.1); 
	color: inherit; 
	text-shadow: none;
}

::selection { 
	background: rgb(10,10,10);
	background: rgba(0,0,0,.1); 
	color: inherit; 
	text-shadow: none;
}
a,
a:visited,
.fakeLink { 
	color: $link;
	text-decoration: none; 
	border-bottom: 1px solid transparent;
	
	&:hover { 
		color: darken($link, 10%); 
		border-color: lighten($link, 50%);
	}
}

a:focus { 
	outline: thin dotted; 
}

a:hover,
a:active { 
	outline: 0; 
}

.btn,
.btn:visited,
.fakeBtn,
input[type="submit"],
input[type="button"] { 
	color: white;
	padding: 8px 14px 9px;
	border: 0 none;
	background: $accent; 
	display: inline-block;
	
	&:hover { 
		color: white;
		background-color: darken($accent, 4%);
	}
	
	&:active {
		background-color: darken($accent, 8%);
	}
	
	&:focus {
		outline: 0;
		border-color: darken($accent, 40%);
	}
	
	@media (max-width: $break1 - 1) {
		width: 100%;
		box-sizing:border-box;
	}
}

.imgLink {
	border: 0;
}
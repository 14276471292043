#menuHandle {
	position: absolute;
	top: 0;
	right: 10px;
	@extend .ir;
	width: 55px;
	height: 300px;
	margin-top: -255px;
	background: rgb(200,200,200);
	border: 0;
}

#mainNav {
	display: block;
	position: absolute;
	top: -120%;
	width: 100%;
	margin: 0 -20px;
	background-color: rgb(200,200,200);
	overflow: hidden;
	z-index: 2;
	
	a {
		display: block;
		width: 100%;
		padding: 15px 20px;
		border-color: rgba(255,255,255,.1);
		color: white;
		
		&.closeSub {
			@extend .ir;
			height: 25px;
			background-image: url(i/sprite.png);
			background-repeat: no-repeat;
			background-position: -1% -704px;
		}
	}
	
	.hasSubNav>a:first-child {
		background-image: url(i/sprite.png);
		background-repeat: no-repeat;
		background-position: 89% -913px;
	}
	
	.sub-menu {
		position: absolute;
		display: block;
		left: 150%;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgb(200,200,200);
		
		li {
			position: relative;
		}
		
		a {
			width: 80%;
		}
	}
	
	li.open .sub-menu {
		left: 10%;
	}
	
	ul li ul {
		display: none;
	}
}

.navOpen {
	
	nav#mainNav {
		top: 0;
	}
	
	#mobileOnly {
		height: 5px;
	}
	
	#menuHandle {
		background-position: -957px -64px;
	}
	
}

#mainNav .current-menu-item a {
	background-color: darken(rgb(200,200,200), 5%);
}

#footNav {
	margin-bottom: 2em;
	
	a {
		padding: 8px 0;
		width: 100%;
		display: block;
	}
}





@media only screen and (min-width: $break1) {
	
	.mobileOnly,
	#menuHandle {
		display: none;
	}
	
	#mainNav {
		position: relative;
		background: transparent;
		overflow: visible;
		margin: 0;
		top: auto;
		font-size: .85em;
		
		ul {
			float: left;
			margin-top: -7px;
			border: 1px solid darken(rgb(200,200,200), 5%); 
			background: rgb(200,200,200);
		}
		
		li {
			float:left;
		}
		
		a {
			display: block;
			line-height: 1.3em;
			outline: 0;
			border: 0;
			color: white;
			background: transparent;
			padding: 8px 12px;
			width: auto;
			
			&:hover {
				background-color: rgb(1,36,34);
				padding: 8px 12px;
				border: 0;
			}
		}
		
		.hasSubNav {
			position: relative;
			
			&>a:first-child {
				background-image: none;
			}
			
			.sub-menu {
				position: absolute;
				left: 0;
				top: 100%;
				width: 150px;
				height: auto;
				z-index: 9999;
				display: none;
				box-shadow: 0 3px 5px rgba(0,0,0,.4);
				margin-top: 0;
				background: transparent none;
				border: 0 none;
				
				li {
					display: block;
					float: left;
					width: 100%;
					
					a {
						color: white;
						background-color: rgb(1,36,34);
						margin: 0;
						display: block;
						padding: 8px 12px;
						width: auto;
						
						&:hover {
							background-color: $black;
						}
					}
				}
			}
			
			&:hover {
				.sub-menu {
					display: block;
				}
			}
		}
	}
			
	nav#mobileOnly {
		display: none;
	}
	
	#mainNav:hover {
		
		a {
			opacity: .5;
		}
		
		li:hover {
			a {
				opacity: 1;
			}
		}
	}
	
	#footNav {
		
		ul {
			width: 100%;
			float: left;
			margin-bottom: 1em;
		}
		
		li {
			float: left;
			margin-right: 20px;
		}
		
		a {
			padding: 0;
		}		
	}
	
}
/***
    The new CSS reset - version 1.4.9 (last updated 11.2.2022)
    GitHub page: https://github.com/elad2412/the-new-css-reset
***/
/*
    Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property
    - The "symbol *" part is to solve Firefox SVG sprite bug
 */
*:where(:not(iframe, canvas, img, svg, video):not(svg *, symbol *)) {
  all: unset;
  display: revert;
}

/* Preferred box-sizing value */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Reapply the pointer cursor for anchor tags */
a, button {
  cursor: revert;
}

/* Remove list styles (bullets/numbers) */
ol, ul, menu {
  list-style: none;
}

/* For images to not be able to exceed their container */
img {
  max-width: 100%;
}

/* removes spacing between cells in tables */
table {
  border-collapse: collapse;
}

/* revert the 'white-space' property for textarea elements on Safari */
textarea {
  white-space: revert;
}

/* minimum style to allow to style meter element */
meter {
  -webkit-appearance: revert;
  appearance: revert;
}

/* reset default text opacity of input placeholder */
::placeholder {
  all: unset;
}

/* fix the feature of 'hidden' attribute.
   display:revert; revert to element instead of attribute */
:where([hidden]) {
  display: none;
}

/* revert for bug in Chromium browsers
   - fix for the content editable attribute will work properly. */
:where([contenteditable]) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
}

/* apply back the draggable feature - exist only in Chromium and Safari */
:where([draggable=true]) {
  -webkit-user-drag: element;
}

body,
select,
input,
textarea,
button {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  line-height: 1.45em;
  color: rgb(240, 240, 240);
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
}

p,
ul,
ol,
ul.long li {
  margin: 0 0 0.8em 0;
}

h1 {
  font-weight: normal;
  font-size: 1.55em;
  line-height: 1.2em;
}

h2 {
  font-weight: normal;
  font-size: 1.55em;
  line-height: 1.2em;
  text-align: center;
  margin: 3em 0;
}
h2:before {
  content: "";
  display: block;
  margin: 0 auto 1em;
  height: 3px;
  width: 30%;
  background-color: rgb(243, 146, 0);
}

.accent {
  color: rgb(243, 146, 0);
}

::-moz-selection {
  background: rgb(10, 10, 10);
  background: rgba(0, 0, 0, 0.1);
  color: inherit;
  text-shadow: none;
}

::selection {
  background: rgb(10, 10, 10);
  background: rgba(0, 0, 0, 0.1);
  color: inherit;
  text-shadow: none;
}

a,
a:visited,
.fakeLink {
  color: rgb(0, 110, 175);
  text-decoration: none;
  border-bottom: 1px solid transparent;
}
a:hover,
a:visited:hover,
.fakeLink:hover {
  color: #004e7c;
  border-color: #afe1ff;
}

a:focus {
  outline: thin dotted;
}

a:hover,
a:active {
  outline: 0;
}

.btn,
.btn:visited,
.fakeBtn,
input[type=submit],
input[type=button] {
  color: white;
  padding: 8px 14px 9px;
  border: 0 none;
  background: rgb(243, 146, 0);
  display: inline-block;
}
.btn:hover,
.btn:visited:hover,
.fakeBtn:hover,
input[type=submit]:hover,
input[type=button]:hover {
  color: white;
  background-color: #df8600;
}
.btn:active,
.btn:visited:active,
.fakeBtn:active,
input[type=submit]:active,
input[type=button]:active {
  background-color: #ca7900;
}
.btn:focus,
.btn:visited:focus,
.fakeBtn:focus,
input[type=submit]:focus,
input[type=button]:focus {
  outline: 0;
  border-color: #271700;
}
@media (max-width: 620px) {
  .btn,
  .btn:visited,
  .fakeBtn,
  input[type=submit],
  input[type=button] {
    width: 100%;
    box-sizing: border-box;
  }
}

.imgLink {
  border: 0;
}

input[type=text],
input[type=email],
input[type=password],
input[type=tel],
textarea {
  border: 0 none;
  padding: 10px;
  padding-top: 3px;
  margin: 0 0 10px 0;
  display: block;
  width: 100%;
  background-color: transparent;
  outline: none;
}
input[type=text]:hover,
input[type=email]:hover,
input[type=password]:hover,
input[type=tel]:hover,
textarea:hover {
  background-color: transparent;
}
input[type=text]:active,
input[type=email]:active,
input[type=password]:active,
input[type=tel]:active,
textarea:active {
  background-color: transparent;
}

.field {
  background-color: rgb(70, 70, 70);
  padding-top: 0.6em;
  transition: 0.3s;
}
.field.submit {
  background-color: transparent;
}

input[type=submit] {
  width: 100%;
  padding: 1em 0;
  cursor: pointer;
  text-align: center;
}

.ie input[type=text],
.ie input[type=email],
.ie input[type=password],
.ie input[type=tel],
.ie textarea {
  border: 2px solid #bbb;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

label {
  display: block;
  color: rgb(243, 146, 0);
  font-size: 0.8em;
  line-height: 1em;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0 10px 0;
}

.optional {
  font-size: 0.85em;
  opacity: 0.7;
}

input[type=checkbox], input[type=radio] {
  margin: 2px 0;
}

::-webkit-input-placeholder {
  color: rgb(100, 100, 100);
}

:-moz-placeholder { /* Firefox 18- */
  color: rgb(100, 100, 100);
}

::-moz-placeholder { /* Firefox 19+ */
  color: rgb(100, 100, 100);
}

:-ms-input-placeholder {
  color: rgb(100, 100, 100);
}

form {
  margin-top: 4em;
}
form p {
  margin-bottom: 2em;
}
form .icon {
  width: 115px;
  height: 115px;
  border-radius: 115px;
  background-color: rgb(243, 146, 0);
  text-align: center;
  margin: 0 auto;
  margin-bottom: 3em;
}
form .icon img {
  position: relative;
  margin-top: 38px;
  z-index: 1;
}
form .icon:after {
  content: "";
  display: block;
  width: 60px;
  height: 60px;
  margin: 0 auto;
  border-radius: 2px;
  background-color: rgb(243, 146, 0);
  transform: rotate(45deg) translate(-17px, -17px);
}
form.processing input,
form.processing textarea {
  pointer-events: none;
}
form.processing .field {
  opacity: 0.5;
}
form.processing .field.submit {
  opacity: 1;
  background-color: rgb(243, 146, 0);
}
form.processing .field.submit input {
  opacity: 0;
}
form.success .field {
  overflow: hidden;
  height: 0;
  margin: 0;
  padding: 0;
}
form.success .field.submit {
  height: auto;
  background-color: rgb(160, 207, 103);
}
form.success .field.submit input {
  opacity: 0;
}
form.success .field.submit:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url(images/tick.png) no-repeat 50% 50%;
  background-image: url(images/tick.svg);
}
form.error .errorMessage {
  display: block;
}
form .errorMessage {
  display: none;
  background-color: rgb(226, 1, 119);
  margin: 1em 0;
  padding: 0.8em;
  color: white;
  border: 0;
}

.field.submit {
  position: relative;
  padding-top: 0;
  margin-top: 0.6em;
}
.field.submit .loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /*margin: 20px auto;*/
}

@media screen and (min-width: 621px) {
  form {
    width: 400px;
    margin-right: auto;
    margin-left: auto;
  }
}
table {
  width: 100%;
  margin: 0 0 10px -1px;
  clear: both;
  font-size: 0.85em;
  line-height: 1.1em;
}
table .divide {
  text-align: center;
  padding: 1em;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
}

td, th {
  text-align: left;
  padding: 1em;
}

tr {
  background-color: rgba(0, 0, 0, 0.07);
}
tbody tr:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

th {
  font-weight: 700;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
}

* {
  box-sizing: border-box;
}

body {
  background-color: rgb(54, 50, 48);
  padding: 2em;
}

@media screen and (min-width: 621px) {
  body {
    width: 920px;
    padding: 4em 40px 8em;
    margin: 0 auto;
  }
}
@media screen and (min-height: 750px) {
  body {
    padding-top: 8em;
  }
}
#menuHandle {
  position: absolute;
  top: 0;
  right: 10px;
  width: 55px;
  height: 300px;
  margin-top: -255px;
  background: rgb(200, 200, 200);
  border: 0;
}

#mainNav {
  display: block;
  position: absolute;
  top: -120%;
  width: 100%;
  margin: 0 -20px;
  background-color: rgb(200, 200, 200);
  overflow: hidden;
  z-index: 2;
}
#mainNav a {
  display: block;
  width: 100%;
  padding: 15px 20px;
  border-color: rgba(255, 255, 255, 0.1);
  color: white;
}
#mainNav a.closeSub {
  height: 25px;
  background-image: url(i/sprite.png);
  background-repeat: no-repeat;
  background-position: -1% -704px;
}
#mainNav .hasSubNav > a:first-child {
  background-image: url(i/sprite.png);
  background-repeat: no-repeat;
  background-position: 89% -913px;
}
#mainNav .sub-menu {
  position: absolute;
  display: block;
  left: 150%;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(200, 200, 200);
}
#mainNav .sub-menu li {
  position: relative;
}
#mainNav .sub-menu a {
  width: 80%;
}
#mainNav li.open .sub-menu {
  left: 10%;
}
#mainNav ul li ul {
  display: none;
}

.navOpen nav#mainNav {
  top: 0;
}
.navOpen #mobileOnly {
  height: 5px;
}
.navOpen #menuHandle {
  background-position: -957px -64px;
}

#mainNav .current-menu-item a {
  background-color: #bbbbbb;
}

#footNav {
  margin-bottom: 2em;
}
#footNav a {
  padding: 8px 0;
  width: 100%;
  display: block;
}

@media only screen and (min-width: 621px) {
  .mobileOnly,
  #menuHandle {
    display: none;
  }
  #mainNav {
    position: relative;
    background: transparent;
    overflow: visible;
    margin: 0;
    top: auto;
    font-size: 0.85em;
  }
  #mainNav ul {
    float: left;
    margin-top: -7px;
    border: 1px solid #bbbbbb;
    background: rgb(200, 200, 200);
  }
  #mainNav li {
    float: left;
  }
  #mainNav a {
    display: block;
    line-height: 1.3em;
    outline: 0;
    border: 0;
    color: white;
    background: transparent;
    padding: 8px 12px;
    width: auto;
  }
  #mainNav a:hover {
    background-color: rgb(1, 36, 34);
    padding: 8px 12px;
    border: 0;
  }
  #mainNav .hasSubNav {
    position: relative;
  }
  #mainNav .hasSubNav > a:first-child {
    background-image: none;
  }
  #mainNav .hasSubNav .sub-menu {
    position: absolute;
    left: 0;
    top: 100%;
    width: 150px;
    height: auto;
    z-index: 9999;
    display: none;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
    margin-top: 0;
    background: transparent none;
    border: 0 none;
  }
  #mainNav .hasSubNav .sub-menu li {
    display: block;
    float: left;
    width: 100%;
  }
  #mainNav .hasSubNav .sub-menu li a {
    color: white;
    background-color: rgb(1, 36, 34);
    margin: 0;
    display: block;
    padding: 8px 12px;
    width: auto;
  }
  #mainNav .hasSubNav .sub-menu li a:hover {
    background-color: rgb(20, 20, 20);
  }
  #mainNav .hasSubNav:hover .sub-menu {
    display: block;
  }
  nav#mobileOnly {
    display: none;
  }
  #mainNav:hover a {
    opacity: 0.5;
  }
  #mainNav:hover li:hover a {
    opacity: 1;
  }
  #footNav ul {
    width: 100%;
    float: left;
    margin-bottom: 1em;
  }
  #footNav li {
    float: left;
    margin-right: 20px;
  }
  #footNav a {
    padding: 0;
  }
}
header {
  text-align: center;
}

h1 {
  margin: 2em 0;
}
h1 span {
  color: rgb(243, 146, 0);
}

@media screen and (min-width: 621px) {
  h1 {
    margin: 2em 0;
  }
}
@media screen and (min-height: 750px) {
  h1 {
    margin: 4em 0;
  }
}
.col {
  display: block;
  margin-bottom: 2em;
  padding: 1px;
}

.cols4 {
  display: flex;
  flex-wrap: wrap;
}
.cols4 .col {
  width: 50%;
}

@media screen and (min-width: 621px) {
  .cols {
    display: flex;
    flex-wrap: wrap;
  }
  .cols.cols4 .col {
    width: 25%;
  }
  .cols.cols2 .col {
    width: 47%;
  }
  .cols.cols2 .col:first-child {
    margin-right: 6%;
  }
}
.block {
  background-color: rgb(41, 41, 41);
  overflow: hidden;
  padding: 2em;
  padding-left: 175px;
}
.block .copy,
.block img {
  float: left;
  margin: 0;
}
.block img {
  margin-left: -230px;
}
.block p {
  margin: 0;
}

@media screen and (max-width: 621px) {
  .block {
    padding-left: 135px;
  }
  .block img {
    width: 160px;
    height: auto;
    margin-left: -185px;
  }
}
@media screen and (min-width: 621px) {
  .copy {
    display: flex;
    align-items: center;
    height: 320px;
  }
}
.loader,
.loader:before,
.loader:after {
  background: #ffffff;
  animation: load1 1s infinite ease-in-out;
  width: 0.5em;
  height: 20px;
}

.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}

.loader:before {
  left: -0.8em;
  animation-delay: -0.32s;
}

.loader {
  text-indent: -9999em;
  margin: 20px auto 0;
  position: relative;
  font-size: 11px;
  transform: translateZ(0);
  animation-delay: -0.16s;
  display: none;
}

.processing .loader {
  display: block;
}

.loader:after {
  left: 0.8em;
}

@keyframes load1 {
  0%, 80%, 100% {
    box-shadow: 0 0 #ffffff;
    height: 20px;
  }
  40% {
    box-shadow: 0 -10px #ffffff;
    height: 10px;
  }
}
#work img {
  width: 100%;
  height: auto;
}
#work h3 {
  color: rgb(243, 146, 0);
  font-size: 0.9em;
  line-height: 1.15em;
  padding-right: 2em;
}
#work h3 span {
  display: block;
  color: white;
}
#work a {
  display: block;
  position: relative;
  border: 0;
  overflow: hidden;
}
#work a:before, #work a:after {
  content: "";
  display: block;
  position: absolute;
  top: -160px;
  right: -160px;
  width: 80px;
  height: 80px;
  background-color: rgb(243, 146, 0);
  transform: rotate(45deg);
  transition: 0.6s;
}
#work a:hover:before {
  opacity: 0.2;
  top: -40px;
  right: -40px;
}
#work a:hover:after {
  top: -48px;
  right: -48px;
}

/*a[href = ""], a[title = ""], a[href ^= http] {
	color: red;
}*/
@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .ir a:after, #menuHandle a:after, #mainNav a.closeSub a:after,
  a[href^="javascript: "]:after,
  a[href^="#"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}
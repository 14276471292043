* { 
	box-sizing: border-box;
}

body {
	background-color: $base;
	padding: 2em;
}

@media screen and (min-width:$break1) {
	
	body {
		width: 920px;
		padding: 4em 40px 8em;
		margin: 0 auto;
	}
	
}

@media screen and (min-height:750px) {
	
	body {
		padding-top: 8em;
	}
	
}
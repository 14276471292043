.loader,
.loader:before,
.loader:after {
	background: #ffffff;
	animation: load1 1s infinite ease-in-out;
	width: .5em;
	height: 20px;
}

.loader:before,
.loader:after {
	position: absolute;
	top: 0;
	content: '';
}

.loader:before {
	left: -.8em;
	animation-delay: -0.32s;
}

.loader {
	text-indent: -9999em;
	margin: 20px auto 0;
	position: relative;
	font-size: 11px;
	transform: translateZ(0);
	animation-delay: -0.16s;
	display: none;
}

.processing .loader {
	display: block;
}

.loader:after {
	left: .8em;
}

@keyframes load1 {
	
	0%,
	80%,
	100% {
		box-shadow: 0 0 #ffffff;
		height: 20px;
	}
	40% {
		box-shadow: 0 -10px #ffffff;
		height: 10px;
	}
	
}
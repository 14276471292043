.block {
	background-color: rgb(41,41,41);
	overflow: hidden;
	padding: 2em;
	padding-left: 175px;
	
	.copy,
	img {
		float: left;
		margin: 0;
	}
	
	img {
		margin-left: -230px;
	}
	
	p {
		margin: 0;
	}
	
}

@media screen and (max-width:$break1) {
	
	.block {
		padding-left: 135px;
		
		img {
			width: 160px;
			height: auto;
			margin-left: -185px;
		}
		
	}
	
}

@media screen and (min-width:$break1) {
	
	.copy {
		display: flex;
		align-items: center;
		height: 320px;
	}
	
}
#work {
	
	img {
		width: 100%;
		height: auto;
	}
	
	h3 {
		color: $accent;
		font-size: .9em;
		line-height: 1.15em;
		padding-right: 2em;
		
		span {
			display: block;
			color: white;
		}
	}
	
	a {
		display: block;
		position: relative;
		border: 0;
		overflow: hidden;
		
		$x: 80px;
		
		&:before,
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: -($x*2);
			right: -($x*2);
			width: $x;
			height: $x;
			background-color: $accent;
			transform: rotate(45deg);
			transition: .6s;
		}
		
		&:hover {
			
			&:before {
				opacity: .2;
				top: -($x*.5);
				right: -($x*.5);
			}
			
			&:after {
				top: -($x*.6);
				right: -($x*.6);
			}
			
		}
		
	}
	
}
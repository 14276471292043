header {
	text-align: center;
}

h1 {
	margin: 2em 0;
		
	span {
		color: $accent;
	}
}

@media screen and (min-width:$break1) {
	
	h1 {
		margin: 2em 0;
	}
	
}

@media screen and (min-height:750px) {
	
	h1 {
		margin: 4em 0;
	}
	
}